import React, { useState } from 'react'
import { Link } from "gatsby"
import Img from "gatsby-image"

import { css } from '@emotion/core'
import { constants } from '../styles/constants'

import { MdAddShoppingCart } from "react-icons/md"

import { MdVisibility, MdVisibilityOff } from "react-icons/md"

const Product = (props) => {
    const {
        name, desc, segment, benefits, examples
    } = props
    // console.log("PRODUCT.JS", props)

    const [exampleOpen, setExampleOpen] = useState(false)

    return (
        <div css={item}>
            <div>
                <h3>Production de <span className="stabilo">{name}</span> <span className="smaller">en Suisse romande</span></h3>
                <p>{desc}</p>
                <div css={meta}>
                    <div>
                        <h4>Ce type de production vidéo est pour</h4>
                        <p>{segment}</p>
                    </div>
                    <div>
                        <h4>Les points forts de ces productions vidéos</h4>
                        <p>{benefits}</p>
                    </div>
                </div>
                {examples && 
                    <div css={portfolio}>
                        <h4>Exemples de production vidéo</h4>
                        <button onClick={() => setExampleOpen(prevState => !prevState)}>
                            {exampleOpen ? 
                                <span><MdVisibilityOff /> Réduire</span>
                                :
                                <span><MdVisibility /> Voir</span>
                            }
                        </button>

                        {exampleOpen &&
                            <div className="grid-scrollable">
                                <div className="product__portfolio-grid">
                                    {examples.map( example => {
    
                                        if ( example.image !== null ) return (
                                            <div key={example.uid} className="product__portfolio-item">
                                                <Link to={`/#production-video-${example.uid}`}>
                                                    <Img fixed={example.image} alt={example.title} />
                                                    <h5>{example.title}</h5>
                                                </Link>
                                            </div>
                                        )

                                        return null
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                }
                <Link to="/#contact" css={buttonCTA}><MdAddShoppingCart /> Demander une offre</Link>
            </div>
        </div>
    )
}

export default Product

const item = css`
    background-color: ${constants.white};
    color: ${constants.black};
    margin-bottom: 50px;
    // transform: rotate(2deg);

    > div {
        padding: 40px 50px;
        // transform: rotate(-2deg);

        @media screen and (max-width: ${constants.breakpoint1}) {
            padding: 20px 20px;
        }

        h3 {
            color: ${constants.black};
            margin: 0 0 10px;
            
            :after {
                content: " ";
                display: block;
                width: 50px;
                margin-top: 20px;
                height: 4px;
                background-color: rgba(0, 0, 0, .08);
                border-radius: ${constants.radiusMax};
            }
        }
    
        p {
            margin-top: 20px;
            margin-bottom: 20px;
            color: ${constants.grey};
            opacity: 1;
        }
    }

`

const meta = css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    div {
        width: 48%;
        background-color: rgba(0, 0, 0, .03);
        padding: 20px;
        border-radius: ${constants.radiusSmall};
        color: ${constants.black};

        @media screen and (max-width: ${constants.breakpoint1}) {
            width: 100%;

            &:first-of-type {
                margin-bottom: 20px;
            }
        }

        h4 {
            color: ${constants.black};
            margin: 0 0 10px;
        }

        p {
            color: ${constants.grey};
            font-size: 14px;
            line-height: 20px;
            margin: 0;
        }
    }
`

const buttonCTA = css`
    display: inline-block;
    padding: 8px 15px;
    margin-top: 20px;
    background-color: ${constants.secondary};
    color: ${constants.black};
    border-radius: ${constants.radiusSmall};
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    letter-spacing: 2px;
    // border: 3px solid ${constants.black};

    svg {
        margin-bottom: -4px;
        margin-right: 6px;
        font-size: 18px;
    }

    &:hover {
        cursor: pointer;
    }
`

const portfolio = css`
    margin-top: 20px;
    background-color: ${constants.black};
    padding: 20px;
    border-radius: ${constants.radiusSmall};
    position: relative;

    button {
        background-color: ${constants.secondary};
        color: ${constants.black};
        padding: 4px 14px;
        border-radius: ${constants.radiusSmall};
        border: none;
        position: absolute;
        top: 13px;
        right: 20px;
        font-size: 14px;
        font-weight: 800;
        text-transform: uppercase;

        svg {
            margin-bottom: -2px;
            margin-right: 5px;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            // color: ${constants.black};
            cursor: pointer;
        }
    }

    h4 {
        color: ${constants.white};
        margin: 0;
    }

    .grid-scrollable {
        overflow-x: scroll;

    }

    h5 {
        color: ${constants.black};
        font-size: 12px;
        margin: 0;
        opacity: .66;
    }

    .product__portfolio-grid {
        display: flex;
        margin-top: 20px;
        max-width: 200px;

        .product__portfolio-item {
            margin-right: 20px;
            background: white;
            border-radius: 5px;

            h5 {
                padding: 0 10px 10px;
            }
        }

        a, a:hover, a:visited, a:active {
            text-decoration: none;
        }

        a:hover {
            h5 {
                opacity: 1;
                background: white;
            }
        }
    }
`