import React from 'react'
import Img from "gatsby-image"

import { css } from '@emotion/core'
import { constants } from '../styles/constants'

import { MdMovie, MdPerson, MdVerifiedUser, MdLocationOn, MdBusiness } from "react-icons/md"

const Project = (props) => {
    // console.log(props)
    const uid = props.uid
    const project = props.data
    const title = project.title.text
    const desc = project.desc
    const image = project.image
    const video = project.video
    const client = project.client.document.data
    const links = project.links.filter( item => item.link.url !== "" ).map( link => ({
        button_text: link.button_text,
        url: link.link.url
    }))

    if ( links.length === 0 && video.embed_url ) {
        links.push({
            url: video.embed_url,
        })
    }

    const quotes = project.quotes.filter( item => item.quote.text !== "" ).map( item => ({
        quote: item.quote.text,
        author: item.author,
        position: item.author_position,
        company: client.name.text,
        city: project.place || client.place,
    }))

    const quote = quotes[0]

    return (
        <div css={item} id={"production-video-" + uid}>
            <div css={main}>
                <div css={left}>
                    {image && <Img
                        fixed={image.fixed}
                        alt={title}
                    />}
                </div>
                <div css={right}>
                    <h3>{title}</h3>
                    <p>{desc}</p>
                    <div css={meta}>
                        {links.map( link => (
                            <div key={link.url}>
                                <MdMovie /> <a href={link.url} target="_blank" rel="noreferrer noopener">{link.button_text || "Voir la vidéo"}</a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {quote &&
                <div css={citation}>
                    <MdPerson />
                    <div>
                        <h6>
                            {quote.author && <span className="verified"><MdVerifiedUser /> {quote.author}</span>}
                            {quote.company && <span className="brandname"><MdBusiness /> {quote.company}</span>}
                            {quote.city && <span className="location"><MdLocationOn /> {quote.city}</span>}
                        </h6>
                        <p>{quote.quote}</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default Project

const item = css`
    margin: 0 0 30px 0;
    position: relative;
    z-index: 5;
`

const main = css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(0, 0, 0, .66);
    margin-bottom: 20px;

    h3 {
        font-size: 19px;
    }

    @media screen and (max-width: ${constants.breakpoint1}) {
        padding: 20px 20px 10px;
        flex-wrap: wrap;
    }
`

const left = css`
    margin-right: 20px;

    .gatsby-image-wrapper {
        display: block;
        vertical-align: bottom;
        line-height: 0; 
    }
`

const right = css`

    h3 {
        margin: 10px 0 10px;
        font-size: 17px;
    }

    p {
        margin: 0 20px 10px 0;
        font-size: 13px;
        line-height: 1.3;
    }
`

const meta = css`
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;

    > div {
        display: inline-block;
        margin-right: 10px;
        padding: 4px 16px;
        background-color: rgba(255, 255, 255, .1);
        border-radius: ${constants.radiusSmall};
    }

    a {
        text-decoration: none;
        line-height: 1;

        :hover {
            color: ${constants.secondary};
        }

    }

    svg {
        margin-bottom: -3px;
    }
`

const citation = css`
    margin-top: 10px;
    margin-bottom: 30px;
    // max-width: 600px;
    margin-left: 220px;
    display: flex;
    align-items: flex-start;
    
    @media screen and (max-width: ${constants.breakpoint1}) {
        margin: -10px 20px 0;
    }

    > svg {
        min-width: 25px;
        height: 25px;
        padding: 5px;
        border-radius: ${constants.radiusMax};
        background-color: ${constants.secondary};
        color: ${constants.black};
        margin-right: 10px;
    }

    > div {
        background-color: rgba(255, 255, 255, .9);
        padding: 15px;
        border-radius: ${constants.radiusSmall} ${constants.radiusMedium} ${constants.radiusMedium} ${constants.radiusMedium};
        
        h6 {
            color: ${constants.black};
            margin: 0 0 2px;

            svg {
                margin-right: 1px;
                margin-bottom: -2px;
                color: ${constants.grey};

            }

            .verified svg {
                color: green;
            }

            span {
                margin-right: 10px;
                margin-bottom: 6px;
                font-family: ${constants.font2};
                display: inline-block;

                &.brandname, &.location {
                    font-weight: 400;
                    font-size: 12px;
                }

                &.brandname {
                    text-transform: uppercase;
                }
            }
        }
        
        p {
            color: ${constants.black};
            font-size: 13px;
            line-height: 1.4; 
            margin: 0;
        }
    }
`