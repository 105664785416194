import React from 'react'

import { css } from '@emotion/core'
import { constants } from '../styles/constants'

import { MdSend } from "react-icons/md"

const Form = () => {

    return (
        <form name="contact" css={main} data-netlify="true" method="post" netlify-honeypot="bot-field" action="/merci">
            <h3><span role="img" aria-label="Prendre contact">👋</span> Contact</h3>

            <div className="form-container">
                <label className="field half-width half-width-left" htmlFor="fullname">
                    <span>Prénom et Nom</span>
                    <input id="fullname" type="text" name="fullname" aria-label="fullname" placeholder="Emilie Duvalais" required />
                </label>

                <input type="hidden" name="bot-field" aria-label="bot field" />
                <input type="hidden" name="form-name"  aria-label="form name" value="contact" />

                <label className="field half-width half-width-right" htmlFor="company">
                    <span>Entreprise</span>
                    <input id="company" type="text" name="company" aria-label="company" placeholder="JurAssurance" required />
                </label>

                <label className="field full-width" htmlFor="website">
                    <span>Votre site web (optionnel)</span>
                    <input id="website" type="text" name="website" aria-label="website" placeholder="https://maxi-suisse.ch" />
                </label>

                <label className="field half-width half-width-left" htmlFor="email">
                    <span>Votre email</span>
                    <input id="email" type="email" name="email" aria-label="email" placeholder="duvalais@maxi-suisse.ch" required/>
                </label>

                <label className="field half-width half-width-right" htmlFor="telephone">
                    <span>Votre téléphone (optionnel)</span>
                    <input id="telephone" type="tel" name="telephone" aria-label="telephone" placeholder="0791234567" />
                </label>

                <label className="field half-width half-width-left" htmlFor="deadline">
                    <span>Echéance (optionnel)</span>
                    <input id="deadline" type="text" name="deadline" aria-label="deadline" placeholder="Dans 2 mois" />
                </label>

                <label className="field half-width half-width-right" htmlFor="budget">
                    <span>Budget estimé (optionnel)</span>
                    <input id="budget" type="text" name="budget" aria-label="budget" placeholder="3000 CHF" />
                </label>

                <label className="field full-width" htmlFor="message">
                    <span>Quel est votre projet (optionnel)</span>
                    <textarea id="message" type="text" name="message" aria-label="message" placeholder="Bonjour ! Je souhaite réaliser une magnifique série de vidéos." rows="4"></textarea>
                </label>
            </div>

            <div>
                <button className="send-button" type="submit" aria-label="Envoyer">Envoyer <MdSend /></button>
            </div>
        </form>
    )
}

export default Form

const main = css`
    padding: 40px;
    background-color: ${constants.white};
    color: ${constants.black};

    h3 {
        color: ${constants.black};
        margin-bottom: 50px;
    }
`