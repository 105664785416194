import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { css } from '@emotion/core'
import { constants } from '../styles/constants'
import Product from "../components/product"
import Form from "../components/form"
import Project from "../components/project"

import { MdSend } from "react-icons/md"

const IndexPage = (props) => {
  
  const products = props.data.products.edges.map(edge => edge.node)
  const projects = props.data.productions.edges.map(edge => edge.node)

  // console.log("Products", products)

  return (
  <Layout>
    <SEO title="Production Vidéo en Suisse romande - Lausanne Genève Montreux Morges Sion Bienne Fribourg - Vidéo Pro" />
    <div css={heroSection}>
      <div className='container'>
        <h1>Production vidéo en Suisse romande</h1>
        <h2>Votre agence de production vidéo à Lausanne, Genève, Morges, Montreux, Fribourg, Bienne, Sion [...] pour toute la Suisse romande</h2>
        <Link to="/#portfolio" css={heroCTA}><span role="img" aria-label="camera-icon">🎥</span> Voir nos réalisations</Link>
      </div>
    </div>

    <div css={productsSection} className='container'>
      <h2>Différents types de production vidéo en Suisse romande</h2>
      {products.map(product => {
        
        let examples
        if ( product.prismic_cat_uid ) {
          examples = projects.filter( project => (
            project.data.parent_products && project.data.parent_products.map( item => item.linked_product.uid ).includes(product.prismic_cat_uid)
          )).map( project => ({
            title: project.data.title.text,
            image: project.data.image.fixed,
            uid: project.uid,
          }))
          // console.log("AFTER MAP", examples)
        }

        return (
          <Product key={product.id} {...product} examples={examples} />
        )
      })}
    </div>

    <div css={portfolioSection} className='container' id="portfolio">
      <h2>Productions vidéo <span className="small">à Lausanne, Genève, Sion, Fribourg, Bienne, Morges...</span></h2>
      {projects.map( project => (
        <Project key={`${project.uid}`} {...project} />
      ))}
    </div>

    <div className="container" css={formSection} id="contact">
      <Form />
    </div>

    <div css={sendThumb}>
      <Link to="/#contact"><MdSend /></Link>
    </div>
  </Layout>
)}

export default IndexPage

export const IndexQuery = graphql`
  query IndexQuery {
    products: allProductsYaml {
      edges {
        node {
          id
          name
          desc
          segment
          benefits
          prismic_cat_uid
        }
      }
    }
    productions: allPrismicProductions(filter: {tags: {eq: "Vidéo"}}) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            links {
              button_text
              link {
                url
              }
            }
            video {
              embed_url
            }
            desc
            place
            subtitle {
              text
            }
            client {
              document {
                ... on PrismicClients {
                  id
                  uid
                  data {
                    name {
                      text
                    }
                    place
                  }
                }
              }
            }
            quotes {
              author
              author_position
              quote {
                text
              }
            }
            image {
              fixed(imgixParams: {q: 100}, width: 200, height: 150) {
                ...GatsbyPrismicImageFixed
              }
            }
            parent_products {
              linked_product {
                uid
              }
            }
          }
        }
      }
    }
  }
`

const heroSection = css`
  padding: 80px 0;
  // position: relative;
  z-index: 2;

  @media screen and (max-width: ${constants.breakpoint1}) {
    padding: 20px 0 50px;
  }

  &::before {
    content: "";
    position: fixed;
    height: 100%;
    width: 100%;
    background-image: url("/doodles.svg");
    background-size: cover;
    background-repeat: repeat;
    background-position: top;
    opacity: .18;
    z-index: -50;
  }

  h1 {
    font-size: 100px;
    z-index: 4;
    position: relative;

    @media screen and (max-width: ${constants.breakpoint1}) {
      font-size: 55px;
    }
  }

  h2 {
    font-family: ${constants.font2};
    font-weight: 300;
    line-height: 40px;
    z-index: 4;
    position: relative;
  }
`

const productsSection = css`
  position: relative;
  z-index: 4;

  h2 {
    margin-bottom: 20px;
  }
`

const formSection = css`
  margin: 160px auto 60px;
`

const sendThumb = css`
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 40px;
  background-color: ${constants.secondary};
  padding: 20px 20px 20px 20px;
  border-top-right-radius: ${constants.radiusMax};
  border-bottom-right-radius: ${constants.radiusMax};
  font-size: 30px;
  box-shadow: ${constants.shadowBig};
  // border: 10px solid ${constants.black};
  border-left: none;
  
  a {
    color: ${constants.black};
    text-decoration: none;
  }
`

const portfolioSection = css`
  padding-top: 160px;

  .small {
    font-size: 20px;
    opacity: .8;
  }
`

const heroCTA = css`
  display: inline-block;
  margin-top: 10px;
  padding: 15px 20px;
  background-color: ${constants.secondary};
  border-radius: ${constants.radiusSmall};
  color: ${constants.black};
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 2px;
  cursor: pointer;
  position: relative;
  z-index: 10;

  &:visited {
    color: ${constants.black};
  }
`